import { plugin as formkitPlugin } from "@formkit/vue"
import * as Sentry from "@sentry/vue"
import { createPinia } from "pinia"
import { useRegisterSW } from "virtual:pwa-register/vue"
import { createApp } from "vue"

import { CONFIG } from "@/config"
import { formkitConfig } from "@/config.formkit"
import { gettext } from "@/utils/Translation"
import App from "./App.vue"
import router from "./routers"

const swUpdateIntervalInMilliseconds = 60 * 60 * 1000 // aka 1 hour

useRegisterSW({
  onRegistered(r) {
    r &&
      setInterval(() => {
        r.update()
      }, swUpdateIntervalInMilliseconds)
  },
})
const app = createApp(App)

if (!CONFIG.DEV) {
  Sentry.init({
    app,
    environment: CONFIG.ENV,
    // Sentry is somehow inferring the release SHA without our help.
    // release: CONFIG.COMMIT_SHA,
    dsn: "https://2763cd70e6915b90bc55d71f57fe7fb5@o4506854126845952.ingest.sentry.io/4506854128680960",
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      // Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["0.0.0.0"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}


app
  .use(router)
  .use(formkitPlugin, formkitConfig)
  .use(createPinia())
  .use(gettext)
  .mount("#app")
